import React from 'react'
import PageWrapper from '../components/pagewrapper'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import {graphql, navigate} from 'gatsby'
import {CircleSpinner} from "react-spinners-kit"
import Footer from '../components/footer'
import {Helmet} from 'react-helmet'




class Maincontact extends React.Component{
  constructor(props) {
    super(props)

    this.state ={
      name: '',
      nameerror:'',
      email: '',
      emailerror:'',
      message: '',
      messageerror:'',
      loading:false,
      netresponse: ''
    }
  }

  handleChange = (e) => {
    console.log(e.target.name)
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

    var {name,email,message} = this.state
    console.log(name, email, message)
    if(!name) {
      this.setState({
        nameerror: this.props.pageContext.langcode === 'EL'? 'KENO ONOMA' : 'NOT NULL'
      })
    }
    else {
      this.setState({
        nameerror: ''
      })
    }

    if(!email.match(pattern)) {
      this.setState({
        emailerror: this.props.pageContext.langcode === 'EL'? 'ΛΑΘΟΣ EMAIL' : 'INVALID EMAIL'
      })
    }
    else {
      this.setState({emailerror:''})
    }
    if(!message) {
      this.setState({
        messageerror: this.props.pageContext.langcode === 'EL'? 'KENO ΜΗΝΥΜΑ' : 'NOT NULL'
      })
    }
    else {
      this.setState({messageerror:''})
    }

    if(name && message && email.match(pattern) ) {
      console.log('PASS')
      this.setState({loading:true, name:'', message:'', email:''})
      var data = {

                "data" : {



                                "data" : {

                                                "name" : this.state.name,

                                                "email" : this.state.email,



                                                "message" : this.state.message

                                }

                }

  }
  var url = `${process.env.BASE_URL}/wd_webform/contact/submit`
  fetch(url, {
   method:'post',
   mode: 'cors',
   // headers: {
   //      'Content-Type': 'application/json',
   //      // 'Content-Type': 'application/x-www-form-urlencoded',
   //  },
   body:JSON.stringify(data)
  })
  .then(function(response) {


  return response.json()


  })
  .then(res => {
   console.log('message sending in fetch', res)
   if(res.data.submission_id) {
     this.setState({loading:false, netresponse:'Message sent'})
   }
   else {
      this.setState({loading:false, netresponse:'Network Error'})
   }

   setTimeout(() => {
     navigate('/')
   }, 1500)
  })
  .catch((error) => {
      this.setState({loading:false, netresponse:'Network Error'})
     console.log("in error", error)

  })
    }



  }




  render() {
    var menu;
    if(this.props.pageContext.langcode === 'EN') {// Gia na perasei to analogo menu ston header
       menu = this.props.pageContext.menuen.links
    }
    else {
      menu = this.props.pageContext.menuel.links
    }
    var langpath =[]
    this.props.pageContext.langcontainer.forEach((item) => { // Gia na presasei ta url sto header
      langpath.push({path:item.entityUrl.path, id:item.entityLanguage.id.toUpperCase()})
    })

    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
  var breadpath = this.props.location.pathname.split(/[/]/)
  breadpath.shift()

  breadpath.forEach((item,i) => {
    var value = null;
    var path = null;
    // console.log(item,i,loc[0])

    if(i === 0) {
      if(item === 'en' || item === 'el') {
        value = 'HOME';
        path=`/${item}`
      }
      else {
        value=item;
        path=`/${item}`
      }
      loc.push({value: value, path: path})
    }
    else {
      value=item;
      path=`${loc[i-1].path}/${item}`
      loc.push({value: value, path: path})
    }





  })


       return (
         <div>
           <Helmet>
             {this.props.pageContext.metainfo.map((item,i) => {
       if(item.key === 'title') {
         return (
           <title key={i}>{item.value}</title>
         )
       }
       else if(item.key === 'canonical') {
         return (
           <link key={i} rel="canonical" href={this.props.path} />
         )
       }

       else if(item.key === 'description') {
         return (
           <meta key={i} name="description" content={item.value}/>
         )
       }

       else {
         return null;
       }
     })}
           </Helmet>
          <PageWrapper>
        <Header info={this.props.data.drupal.contactInfo} logourl={this.props.data.drupal.headerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} language={this.props.pageContext.langcode} menu={menu} langcontainer={langpath} path={this.props.path}/>
          <Breadcrumb background={this.props.pageContext.background} title={this.props.pageContext.title} path={loc}/>

            <div className="section-full p-tb80 inner-page-padding">

                    <div className="container">


                        <div className="section-content">

                            <div className="row">
                            	<div className="col-md-8 col-sm-6">
                                	<form className="contact-form cons-contact-form" >
                                        <div className="contact-one m-b30">


                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-left">
                                                <div className="mt-separator">
                                                    <h2 className="text-uppercase sep-line-one ">
                                                      <span className="font-weight-300 text-primary">{this.props.pageContext.langcode === 'EN'? 'Get' : 'Φορμα'}</span>
                                                       <br/> {this.props.pageContext.langcode === 'EN'? 'in touch' : 'Επικοινωνιας'}
                                                      </h2>
                                                </div>
                                            </div>
                                        </div>


                                            <div className="form-group">
                                                <input value={this.state.name} onChange={this.handleChange} name="name" type="text" required="" className="form-control" placeholder={this.props.pageContext.langcode === 'EN'? 'Name' : 'Ονομα'}/>
                                                <div style={{color:'red'}}>{this.state.nameerror}</div>
                                            </div>

                                            <div className="form-group">
                                                <input  value={this.state.email} onChange={this.handleChange} name="email" type="text" className="form-control" required="" placeholder={this.props.pageContext.langcode === 'EN'? 'Email' : 'Ηλεκτρονικη διευθυνση'}/>
                                                <div style={{color:'red'}}>{this.state.emailerror}</div>
                                            </div>

                                            <div className="form-group">
                                                <textarea value={this.state.message} onChange={this.handleChange} name="message" rows="4" className="form-control " required="" placeholder={this.props.pageContext.langcode === 'EN'? 'Message' : 'Μηνυμα'}></textarea>
                                                <div style={{color:'red'}}>{this.state.messageerror}</div>
                                                  <CircleSpinner
size={30}
color="#686769"
loading={this.state.loading}
/>

                                     <h2>{this.state.netresponse}</h2>
                                            </div>

                                        	<div className="text-right">
                                                <button  onClick={this.handleSubmit} name="submit" type="submit" value="Submit" className="site-button btn-effect">
                                                 {this.props.pageContext.langcode === 'EN'? 'Submit' : 'Αποστολη'}
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                	<div className="contact-info m-b30">

                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-left">
                                                <div className="mt-separator">
                                                    <h2 className="text-uppercase sep-line-one ">
                                                      <span className="font-weight-300 text-primary">{this.props.pageContext.langcode === 'EN'? "Contact": "Στοιχεια"}</span>
                                                      <br/>
                                                       {this.props.pageContext.langcode === 'EN'? "Info" : 'Επικοινωνιας'}
                                                      </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bg-dark p-a20 text-white">
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone"></i></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">{this.props.pageContext.langcode === 'EN'? 'Phone number' : 'Τηλεφωνο'}</h5>
                                                    <p>{this.props.data.drupal.contactInfo.fieldPhone}</p>
                                                </div>
                                            </div>

                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-envelope"></i></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">{this.props.pageContext.langcode === 'EN'? 'Email address' : 'Ηλεκτρονικη διευθυνση'}</h5>
                                                    <p>{this.props.data.drupal.contactInfo.fieldEmail}</p>
                                                </div>
                                            </div>

                                            <div className="mt-icon-box-wraper left">
                                                <div className="icon-xs"><i className="fa fa-map-marker"></i></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">{this.props.pageContext.langcode === 'EN'? 'Address info' : 'Διευθυνση'}</h5>
                                                    <p>{this.props.data.drupal.contactInfo.fieldAddress}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
               </div>



   <Footer social={this.props.data.drupal.social} logourl={this.props.data.drupal.footerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} language={this.props.pageContext.langcode} menu={menu}  info={this.props.data.drupal.contactInfo}/>
         </PageWrapper>
        </div>
       )
  }






}

export default Maincontact;


export const query = graphql`
query($langcode: DRUPAL_LanguageId!)  {
  drupal {
    footerLogo:nodeById(id: "25") {
     ... on DRUPAL_NodeLogo {

       fieldLogoimage {
             entity {
               thumbnail {
                 url
                 alt
                 imageFile {
                   publicURL
                 }
               }
             }
           }
     }
   }
    headerLogo: nodeById(id: "24") {
   entityLabel
   ... on DRUPAL_NodeLogo {

     fieldLogoimage {
           entity {
             thumbnail {
               url
               alt
               imageFile {
                 publicURL
               }
             }
           }
         }
   }
 }
 social:nodeQuery(filter: {conditions: [{field: "type", value: "Social_Links"}]}) {
    entities {
      ... on DRUPAL_NodeSocialLinks {

        fieldIcon
        fieldLink
      }
    }
  }
    contactInfo: nodeById(id: "26", language: $langcode) {
   title
   ... on DRUPAL_NodeContactInfo {
     fieldAddress
     fieldEmail
     fieldPhone
   }
 }

}
}`
